import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Slidebar from "../../img/realstate.png";
import Ecommerce from "../../img/fitclub.png";
import HOC from "../../img/weatherApp.png";
import MusicApp from "../../img/musicapp.png";

const Portfolio = () => {
  return (
    <div className="portfolio" id="Portfolio">
      {/*Heading*/}
      <span>Recent Projects</span>
      <span>Portfolio</span>
      {/*Slider*/}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <a href="https://kazi-real-estate-web.pages.dev/">
            <img src={Slidebar} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://fitclubkazi.pages.dev/">
            <img src={Ecommerce} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://kazi-weather-app.pages.dev/">
            <img src={HOC} alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <img src={MusicApp} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
