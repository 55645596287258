import "./Services.css";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import Card from "../Card/Card";
import Resume from "./KaziTSAResume.pdf";
import { motion } from "framer-motion";

const Services = () => {
  const transition = { duration: 2, type: "spring" };
  return (
    <div className="services" id="Services">
      {/* left side*/}
      <div className="awesome">
        <span>My Awesome</span>
        <span>Services</span>
        <span>
          Developing and maintaining web applications that are optimized for
          various devices and browsers.
          <br />
          Implementing responsive design and cross-browser compatibility for
          different screen sizes. <br /> Developing user-friendly and
          interactive web features such as buttons, forms, and menus. <br />{" "}
          Using JavaScript libraries and frameworks such as React or Angular to
          build complex web applications and interactive features. <br />{" "}
          Writing and maintaining code that is clean, efficient, and easy to
          understand. <br /> Testing web applications to ensure they are
          bug-free and work seamlessly for the end-users.
        </span>
        <a href={Resume} download>
          <button className="button s-button">Download CV</button>
        </a>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>

      {/* right side*/}
      <div className="cards">
        <motion.div
          initial={{ left: "25%" }}
          whileInView={{ left: "14rem" }}
          transition={transition}
          style={{ left: "14rem" }}
        >
          <Card
            emoji={HeartEmoji}
            heading={"Design"}
            details={"Bootstrap,Material Ui, Framer-Motion,Tailwind"}
          />
        </motion.div>
        <motion.div
          initial={{ left: "25%" }}
          whileInView={{ left: "-5rem" }}
          transition={transition}
          style={{ top: "12rem", left: "-4rem" }}
        >
          <Card
            emoji={Glasses}
            heading={"Developer"}
            details={"Html, Css, Javascript, Rect+Redux, Bootstrap"}
          />
        </motion.div>
        <motion.div
          initial={{ left: "25%" }}
          whileInView={{ left: "11rem" }}
          transition={transition}
          style={{ top: "19rem", left: "12rem" }}
        >
          <Card
            emoji={Humble}
            heading={"UI/UX"}
            details={
              " Design Specialization brings a design-centric approach to user interface and user experience design."
            }
          />
        </motion.div>
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;
